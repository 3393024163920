import * as React from 'react';
import { Link } from "react-router-dom";
import './Footer.css';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

function Footer() {
  return (
    <footer className="Footer">
      <Container maxWidth="lg">
        <Stack
          spacing={0.5} 
          direction="column"
        >
          <Stack
            spacing={0.5} 
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ flexGrow: 1,  }}
          >
            <MenuItem
              component={Link}
              to="/"
            >
              <Typography
                textAlign="center"
                sx={{
                  textDecoration: 'none',
                }}
              >
                Home
              </Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to="/terms"
            >
              <Typography
                textAlign="center"
                sx={{
                  textDecoration: 'none',
                }}
              >
                Terms
              </Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to="/about"
            >
              <Typography
                textAlign="center"
                sx={{
                  textDecoration: 'none',
                }}
              >
                About
              </Typography>
            </MenuItem>
          </Stack>
          <Typography
            textAlign="center"
            sx={{
              textDecoration: 'none',
              fontSize: '0.75rem',
            }}
          >
            &copy; 2022 FourLetterData
          </Typography>
        </Stack>
      </Container>
    </footer>
  );
}

export default Footer;
