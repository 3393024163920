import React, { useState } from 'react';
import './Schema.css';
/* MUI */
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
/* MUI Icons */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DataObjectIcon from '@mui/icons-material/DataObject';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

function Schema() {
  const [schemaData, setSchemaData] = useState({});
  const [schemaColumns, setSchemaColumns] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [schemaType, setSchemaType] = useState("all");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const ignoreColumns = ["cse_thumbnail","cse_image","metatags"];

  const fetchSchema = async () => {
    setLoading(true);
    setSchemaData({});
    const response = fetch(`${process.env.REACT_APP_API}/schema?search=${encodeURIComponent(searchTerm)}&type=${encodeURIComponent(schemaType)}`)
      .then(response => {
        if (!response.ok) { throw response }
        return response.json();
      })
      .then((data => {
        setError("");
        setSchemaData(data);
        const columnNames = [];
        data.results.forEach(result => {
          Object.keys(result.pagemap).forEach((data, key) => {
            if (columnNames.indexOf(data) === -1 && ignoreColumns.indexOf(data) === -1) {
              columnNames.push(data);
            }
          });
        });
        setSchemaColumns(columnNames.sort());
        setLoading(false);
      }))
      .catch(error => {
        if (error.status === 429) {
          setError("Too many requests - please wait and try again later");
        }
        else {
          setError("Couldn't process your request");
        }
        setSchemaData({});
        setSchemaColumns([]);
        setLoading(false);
      });
  }

  const handleSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSchemaType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSchemaType(event.target.value);
  };

  function SchemaCell(props: {rows: any}) {
    const { rows } = props;
    const [open, setOpen] = useState(false);
    const itemTypes = [];

    rows.forEach((data, dataKey) => {
      Object.keys(data).forEach((dataType) => {
        if (itemTypes.indexOf(dataType) === -1) {
          itemTypes.push(dataType)
        }
      })
    });

    const itemCounts = itemTypes
      .map(thisItem => ({
        type: thisItem,
        count: rows.filter((row) => {
                return thisItem in row;
              }).length
      }));

    return (
      <React.Fragment>
        <Stack direction="row" alignItems="flex-start" spacing={0.25}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
          <Box>
          {open ?
            <Stack
              direction="column"
              divider={<Divider orientation="horizontal" flexItem />}
              spacing={0.25}
              sx={{
                px: 2,
                overflowY: 'auto',
                maxHeight: 300,
              }}
            >
              {rows.map((data, dataKey) => (
                Object.keys(data).map((dataType, itemKey) => {
                  return (
                    <Grid key={itemKey} spacing={1}>
                      <Grid item xs={4}>
                        <Typography
                          variant="dataLabel"
                          sx={{m: "auto", ml: 0, mr: 0}}
                        >
                          {dataType}
                        </Typography>
                      </Grid>
                      <Grid item xs>{data[dataType]}</Grid>
                    </Grid>
                  )
                })
              ))}
            </Stack>
            : 
            <Stack direction="column" alignItems="flex-start" spacing={0.25}>
              {itemCounts.map((item, key) => (
                <Chip
                  key={key}
                  avatar={<Avatar>{item.count}</Avatar>}
                  label={item.type}
                  size="small"
                />
              ))}
            </Stack>
          }
          </Box>
        </Stack>
      </React.Fragment>
    );
  }

  return (
    <div className="App">
      <Container maxWidth="100%" sx={(theme) => ({p: 2, backgroundColor: theme.palette.neutral.main})}>
        <Stack spacing={2} direction="row">
          <Typography
            variant="h1"
            sx={{m: "auto", ml: 0, mr: 0}}
          >
            Schema Search
          </Typography>
          <FormControl sx={{width: 150, backgroundColor: 'white' }}>
            <InputLabel id="schema-type">Schema Type</InputLabel>
            <Select
              labelId="schema-type-label"
              id="schema-type-select"
              value={schemaType}
              label="Schema Type"
              onChange={handleSchemaType}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"product"}>Products</MenuItem>
              <MenuItem value={"video"}>Videos</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="schema-fetch"
            sx={{ width: 300, backgroundColor: 'white' }}
            label="Schema Search"
            onChange={handleSearchTerm}
          />
          <LoadingButton variant="contained" onClick={fetchSchema} loading={loading}>
            Find Pages with Schema
          </LoadingButton>
        </Stack>
      </Container>
      {error && (
        <Container maxWidth="lg" sx={{mt: 2}}>
          <Alert variant="outlined" severity="error">
            {error}
          </Alert>
        </Container>
      )}
      {schemaData && schemaData.success && (
        <TableContainer component={Paper} sx={{m: 0, p: 0, maxHeight: 'calc(100vh - 9.5rem)'}}>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: 350 }}>Page</TableCell>
                {schemaColumns && schemaColumns.map((column, key) => {
                  return (<TableCell align="center" key={key}>{column}</TableCell>)
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {schemaData.results.length > 0 && schemaData.results.map((row, key) => (
                <TableRow
                  key={key}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                    <Grid container>
                      <Grid item xs={4}>
                        {row.pagemap['cse_thumbnail'] && (
                          <Img
                            src={`${row.pagemap['cse_thumbnail'][0].src}`}
                            loading="lazy"
                          />
                        )}
                      </Grid>
                      <Grid item container xs={8}>
                        <Stack>
                          <div>{row.title}</div>
                          <div>{row.snippet}</div>
                        </Stack>
                      </Grid>
                    </Grid>
                  </TableCell>
                  {schemaColumns && schemaColumns.map((column, columnKey) => (
                    <TableCell key={columnKey.toString()}>
                      {column.toString() in row.pagemap
                        ? <SchemaCell rows={row.pagemap[column.toString()]} />
                        : ""
                      }
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {loading === true && (
        <Box sx={{height: "max-content"}}>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          {[...Array(4)].map((item, key) => (
            <Stack key={key} direction="row" spacing={2}>
              <Box sx={{ my: 2, mx: 1, width: '2%'}}>
                <Skeleton variant="circular" width={40} height={40} />
              </Box>
              {[...Array(4)].map((item, key) => (
                <Skeleton key={key} variant="text" sx={{ my: 2, mx: 1, width: '23%'}} />
              ))}
            </Stack>
          ))}
        </Box>
      )}
      {error === "" && loading === false && Object.keys(schemaData).length === 0 && (
        <Container maxWidth="lg" sx={{mt: 2, textAlign: 'left'}}>
          <Card>
            <Stack direction="row" alignItems="flex-start" spacing={0.25}>
              <CardMedia
                sx={{
                  m: 4
                }}
              >
                <DataObjectIcon fontSize="large" />
              </CardMedia>
              <CardContent>
                <Typography variant="h3">
                  Schema Search
                </Typography>
                <Typography variant="p">
                  Search for pages that contain schema, microdata, structured data, and JSON+LD markup.
                </Typography>
                <Typography variant="p">
                  Enter a search term and select optional schema types to find websites that contain structured data. Most pages with structured data are indexed by Google but not necessarily in the same order.
                </Typography>
                <Typography variant="p">
                  Search results will include
                  <ul>
                    <li>Title, meta description, and thumbnail (if available)</li>
                    <li>Summary of all structured data types found on each page</li>
                    <li>Detailed list of schema properties and data for each schema definition</li>
                  </ul>
                </Typography>
              </CardContent>
            </Stack>
          </Card>
        </Container>
      )}
    </div>
  );
}

export default Schema;
