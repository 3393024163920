import React, { useEffect, useState } from 'react';
import {socket} from "../../api/socket";
import './Fetch.css';
/* MUI */
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
/* MUI Icons */
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';

//const socket = io.connect(`${process.env.REACT_APP_API}`);

function Fetch() {
  const [pageData, setPageData] = useState({});
  const [requestURL, setRequestURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState([]);

  const fetchUrl = () => {
    setError("");
    setPageData({});
    setProgress([]);
    !socket.connected && socket.connect();
    socket.on("disconnect", (reason) => {
      //setError(reason);
      setLoading(false);
      setProgress([]);
    });
    socket.emit("fetch_start", requestURL);
  };

  useEffect(() => {
    socket.on("fetch_started", data => {
      setProgress([]);
      setError("");
      setPageData({});
      setLoading(true);
    });
    socket.on("fetch_complete", (data) => {
      setError("");
      setPageData(data);
      setLoading(false);
      setProgress([]);
      socket.disconnect();
    });
    socket.on("fetch_failed", (data) => {
      setError("Couldn't process your request");
      setPageData({});
      setLoading(false);
      setProgress([]);
      socket.disconnect();
    });
    socket.on("fetch_progress", (data) => {
      setProgress(prev => [...prev, data]);
    });
    return () => {
      socket.off("fetch_started");
      socket.off("fetch_complete");
      socket.off("fetch_failed");
    }
  }, []);

  const handleURL = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequestURL(event.target.value);
  };

  return (
    <div className="Fetch">
      <Container maxWidth="100%" sx={(theme) => ({p: 2, backgroundColor: theme.palette.neutral.main})}>
        <Stack spacing={2} direction="row">
          <Typography
            variant="h1"
            sx={{m: "auto", ml: 0, mr: 0}}
          >
            Fetch URL
          </Typography>
          <TextField
            id="url-fetch"
            sx={{ width: 300, backgroundColor: 'white' }}
            label="URL Fetch"
            onChange={handleURL}
          />
          <LoadingButton variant="contained" onClick={fetchUrl} loading={loading}>Fetch Page</LoadingButton>
        </Stack>
      </Container>
      <Container maxWidth="lg" sx={{mt: 2}}>
        {error && (
          <Alert variant="outlined" severity="error">
            {error}
          </Alert>
        )}
        {Object.keys(progress).length !== 0 && (
          <Stack spacing={1} direction="column" sx={{m: 1}}>
            {progress.map((update, key) => (
              key < Object.keys(progress).length - 1
                ? (<Alert severity="success" key={key}>{update}</Alert>)
                : (
                  <Alert
                    severity="info"
                    icon={
                      <CircularProgress size={20} />
                    }
                    key={key}
                  >
                    {update}
                  </Alert>
                )
            ))}
          </Stack>
        )}
        {pageData && pageData.success && (
          <Stack spacing={2} direction="column" sx={{m: 1}}>
            <Grid container spacing={2} columns={16}>
              <Grid item xs={4}>
                <div className="Fetch-Label">URL Resolution</div>
              </Grid>
              <Grid item xs={12}>
                {pageData.request && pageData.request.map((item, key) => {
                  return (
                    <Stack key={key} direction="row" spacing={1} sx={{m: 0.5}}>
                      <Chip label={item[1]} color={item[1] < 400 ? item[1] < 300 ? item[1] < 200 ? "error" : "success" : "warning" : "error"} />
                      <div className="Fetch-Data">{item[0]}</div>
                    </Stack>);
                })}
              </Grid>
            </Grid>
            <Grid container spacing={2} columns={16}>
              <Grid item xs={4}>
                <div className="Fetch-Label">Title</div>
              </Grid>
              <Grid item xs={12}>
                <Stack alignItems="flex-start">
                  <Box>{pageData.title}</Box>
                  <Box>
                    <Chip label={`${pageData.title.length} characters`} />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2} columns={16}>
              <Grid item xs={4}>
                <div className="Fetch-Label">Meta Description</div>
              </Grid>
              <Grid item xs={12}>
                <Stack alignItems="flex-start">
                  <Box>{pageData.description}</Box>
                  <Box>
                    <Chip label={`${pageData.description.length} characters`} />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2} columns={16}>
              <Grid item xs={4}>
                <div className="Fetch-Label">Canonical URL</div>
              </Grid>
              <Grid item xs={12}>
                <div className="Fetch-Data">{pageData.canonical}</div>
              </Grid>
            </Grid>
            <Grid container spacing={2} columns={16}>
              <Grid item xs={4}>
                <div className="Fetch-Label">H1 Tags</div>
              </Grid>
              <Grid item xs={12}>
                <List aria-label="h1-labels">
                  {pageData.h1 && pageData.h1.map((value, key) => {
                    return (
                      <ListItem key={key}>
                        <ListItemText primary={value} />
                      </ListItem>);
                  })}
                </List>
              </Grid>
            </Grid>
            <Grid container spacing={2} columns={16}>
              <Grid item xs={4}>
                <div className="Fetch-Label">H2 Tags</div>
              </Grid>
              <Grid item xs={12}>
                <List className="Fetch-Data" aria-label="h2-labels">
                  {pageData.h2 && pageData.h2.map((value, key) => {
                    return (
                      <ListItem key={key}>
                        <ListItemText primary={value} />
                      </ListItem>);
                  })}
                </List>
              </Grid>
            </Grid>
            <Grid container spacing={2} columns={16}>
              <Grid item xs={4}>
                <div className="Fetch-Label">Keywords</div>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ height: 400, width: '100%' }}>
                  <DataGrid
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'count', sort: 'desc' }],
                      },
                    }}
                    columns={[
                      { field: 'ngram', headerName: 'Keyword', width: 350 },
                      { field: 'count', headerName: 'Count' }
                    ]}
                    rows={pageData.ngrams}
                  />
                </Box>
              </Grid>
            </Grid>
          </Stack>
        )}
        {error === "" && loading === false && Object.keys(pageData).length === 0 && (
          <Container maxWidth="lg" sx={{mt: 2, textAlign: 'left'}}>
            <Card>
              <Stack direction="row" alignItems="flex-start" spacing={0.25}>
                <CardMedia
                  sx={{
                    m: 4
                  }}
                >
                  <BrowserUpdatedIcon fontSize="large" />
                </CardMedia>
                <CardContent>
                  <Typography variant="h3">
                    URL Fetcher
                  </Typography>
                  <Typography variant="p">
                    Request a URL and analyze the URL resolution, page meta data, and keyword density of on-page content.
                  </Typography>
                  <Typography variant="p">
                    Fetched URL data will include
                    <ul>
                      <li>URL status including redirects and error codes</li>
                      <li>Page meta data including title and meta description</li>
                      <li>Indicated canonical tags</li>
                      <li>Top level page headings</li>
                      <li>Keywords found on page including ngram and occurances</li>
                    </ul>
                  </Typography>
                </CardContent>
              </Stack>
            </Card>
          </Container>
        )}
      </Container>
    </div>
  );
}

export default Fetch;
