import React, { useEffect, useState } from 'react';
import { socket } from "../../api/socket";
import { CSVLink } from "react-csv";
import './TFIDF.css';
/* MUI */
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
/* MUI Icons */
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AddIcon from '@mui/icons-material/Add';
import BackspaceIcon from '@mui/icons-material/Backspace';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

function TFIDF() {
  const [mode, setMode] = useState("crawl");
  const [pageData, setPageData] = useState({});
  const [columns, setColumns] = useState([]);
  const [requestURL, setRequestURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState([]);

  const fetchUrl = () => {
    setError("");
    setPageData({});
    setProgress([]);
    !socket.connected && socket.connect();
    socket.on("disconnect", (reason) => {
      //setError(reason);
      setLoading(false);
      setProgress([]);
    });
    socket.emit("tfidf_start", requestURL);
  };

  useEffect(() => {
    socket.on("tfidf_started", data => {
      setProgress([]);
      setError("");
      setPageData({});
      setLoading(true);
    });
    socket.on("tfidf_complete", (data) => {
      setError("");
      setPageData(data);
      setLoading(false);
      setProgress([]);
      socket.disconnect();
    });
    socket.on("tfidf_failed", (data) => {
      setError("Couldn't process your request");
      setPageData({});
      setLoading(false);
      setProgress([]);
      socket.disconnect();
    });
    socket.on("tfidf_progress", (data) => {
      setProgress(prev => [...prev, data]);
    });
    return () => {
      socket.off("tfidf_started");
      socket.off("tfidf_complete");
      socket.off("tfidf_failed");
    }
  }, []);


  useEffect(() => {
    const cols = [
      {'field': 'name', 'headerName': 'Keyword', 'width': 200, 'label': 'Keyword', 'key': 'name'},
      {'field': 'reldoc', 'headerName': 'Relevant Page', 'renderCell': UrlCell, 'width': 250, 'label': 'Page', 'key': 'reldoc'},
      {'field': 'freq', 'headerName': 'Frequency', 'label': 'Frequency', 'key': 'freq'},
    ];
    pageData.urls && pageData.urls.forEach((url) => {
      cols.push(
        {'field': url.id, 'headerName': url.url, 'renderCell': ColorCell, 'renderHeader': UrlHeader, 'label': url.url, 'key': url.id}
      );
    });
    setColumns(cols);
  }, [pageData]);

  const handleMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMode(event.target.value);
  };

  const handleURL = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequestURL(event.target.value);
  };

  const handleReset = () => {
    setError("");
    setPageData({});
    setLoading(false);
  }

  function UrlHeader(props: {value: any}) {
    const value = props.colDef.headerName;
    let label = value;
    let link = false;
    try {
      const urlObj = new URL(value);
      if (urlObj['pathname']) {
        label = urlObj['pathname'];
        link = true;
      };
    } catch {};
    return (
      <React.Fragment>
        {link
          ? <a href={value} target="_blank" rel="noreferrer">...{label}</a>
          : <>{label}</>
        }
      </React.Fragment>
    );
  };

  function UrlCell(props: {value: any}) {
    const { value } = props;
    let label = value;
    let link = false;
    try {
      const urlObj = new URL(value);
      if (urlObj['pathname']) {
        label = urlObj['pathname'];
        link = true;
      };
    } catch {};
    return (
      <React.Fragment>
        {link
          ? <a href={value} target="_blank" rel="noreferrer">...{label}</a>
          : <>{label}</>
        }
      </React.Fragment>
    );
  };

  function ColorCell(props: {value: any}) {
    const { value } = props;

    return (
      <React.Fragment>
        <Box
          sx={{
            height: '100%',
            width: '100%',
            backgroundColor: `rgb(255, ${255 - Math.round((Number(value)/Number(pageData.maxScore)) * 255)}, ${255 - Math.round((Number(value)/Number(pageData.maxScore)) * 255)})`
          }}
        >{Number(value).toFixed(3)}</Box>
      </React.Fragment>
    );
  };

  return (
    <div className="TFIDF">
      <Container maxWidth="100%" sx={(theme) => ({p: 2, backgroundColor: theme.palette.neutral.main})}>
        <Stack spacing={2} direction="row">
          <Typography
            variant="h1"
            sx={{m: "auto", ml: 0, mr: 0}}
          >
            TF/IDF Analysis
          </Typography>
          {pageData && pageData.success && (
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                onClick={handleReset}
                startIcon={<BackspaceIcon />}
              >
                
                Start Over
              </Button>
              {columns && (
                <CSVLink
                  headers={columns}
                  data={pageData.tfidf}
                  filename={"tfidf-analysis.csv"}
                  target="_blank"
                >
                  <Button
                    variant="outlined"
                    startIcon={<DownloadForOfflineIcon />}
                  >
                    Download CSV
                  </Button>
                </CSVLink>
              )}
              <Chip
                avatar={<Avatar>{pageData.urls.length}</Avatar>}
                label={requestURL}
              />
            </Stack>
          )}
        </Stack>
      </Container>
      {error && (
        <Container maxWidth="lg" sx={{mt: 2}}>
          <Alert variant="outlined" severity="error">
            {error}
          </Alert>
        </Container>
      )}
      {Object.keys(progress).length !== 0 && (
        <Container maxWidth="lg" sx={{mt: 2}}>
          <Stack spacing={1} direction="column-reverse" sx={{m: 1}}>
            {progress.map((update, key) => (
              key < Object.keys(progress).length - 1
                ? (<Alert severity="success" key={key}>{update}</Alert>)
                : (
                  <Alert
                    severity="info"
                    icon={
                      <CircularProgress size={20} />
                    }
                    key={key}
                  >
                    {update}
                  </Alert>
                )
            ))}
          </Stack>
        </Container>
      )}
      {pageData && pageData.success && (
        <Container
          maxWidth="100%"
          disableGutters={true}
          sx={{m: 0, p: 0, height: 'calc(100vh - 9.5rem)', width: '100%'}}
          >
          <DataGrid
            density="compact"
            columns={columns}
            rows={pageData.tfidf}
          />
        </Container>
      )}
      {false && (
        <TableContainer component={Paper} sx={{m: 0, p: 0, maxHeight: 'calc(100vh - 9.5rem)'}}>
          <Table stickyHeader sx={{ minWidth: 200}}  size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Keyword</TableCell>
                <TableCell>Occurances</TableCell>
                <TableCell sx={{ minWidth: 150}}>Most Relevant Page</TableCell>
                {pageData.urls.map((url, index) => (
                  <TableCell key={index}>
                    <UrlCell url={url} />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pageData.tfidf.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {row.map((item, index) => (
                    <>
                    {index === 2 && item !== "-"
                      ? (<TableCell key={index}>
                          <UrlCell url={item} /> 
                        </TableCell>)
                      : (index > 2
                        ? <TableCell 
                            key={index}
                            sx={{
                              backgroundColor: `rgb(255, ${255 - Math.round((Number(item)/Number(pageData.maxScore)) * 255)}, ${255 - Math.round((Number(item)/Number(pageData.maxScore)) * 255)})`
                            }}>
                            </TableCell>
                        : (<TableCell key={index}>{item}</TableCell>))
                    }
                    </>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {error === "" && loading === false && Object.keys(pageData).length === 0 && (
        <Container maxWidth="lg" sx={{mt: 2, textAlign: 'left'}}>
          <Card sx={{mb: 2}}>
            <Grid container columns={16}>
              <Grid item xs={4} sx={{py: 1, pl: 4}}>
                <Stack spacing={0.5}>
                  <FormControl>
                    <RadioGroup
                      defaultValue="crawl"
                      name="radio-buttons-group"
                      onChange={handleMode}
                    >
                      <FormControlLabel value="crawl" control={<Radio />} label="Crawl" />
                      <FormControlLabel value="list" control={<Radio disabled />} label="List" />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                {mode === "crawl" ? (
                  <Stack direction="row" spacing={2} sx={{pt: 2}}>
                    <TextField
                      id="url-fetch"
                      sx={{ width: 300, backgroundColor: 'white' }}
                      label="URL Crawl"
                      onChange={handleURL}
                    />
                    <LoadingButton variant="contained" onClick={fetchUrl} loading={loading}>Start Crawl</LoadingButton>
                  </Stack>
                ) : (
                  <Stack direction="column">
                    <Stack direction="row" spacing={2}>
                      <TextField
                        id="url-fetch"
                        sx={{ width: 300, backgroundColor: 'white' }}
                        label="New URL"
                        onChange={handleURL}
                      />
                      <LoadingButton variant="contained" onClick={fetchUrl} loading={loading}>
                        <AddIcon />
                      </LoadingButton>
                    </Stack>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Card>
          <Card>
            <Stack direction="row" alignItems="flex-start" spacing={0.25}>
              <CardMedia
                sx={{
                  m: 4
                }}
              >
                <TextSnippetIcon fontSize="large" />
              </CardMedia>
              <CardContent>
                <Typography variant="h3">
                  TF/IDF Analysis
                </Typography>
                <Typography variant="p">
                  Use TF/IDF analysis on your website or list of pages to identify commonly used terms and keywords that are unique to each page.
                </Typography>
                <Typography variant="p">
                  <ul>
                    <li>Crawl pages or a list of URL</li>
                    <li>Get list of ngrams and instances</li>
                    <li>Find most relevant page for keywords</li>
                    <li>TF/IDF scores for each page and keyword</li>
                  </ul>
                </Typography>
              </CardContent>
            </Stack>
          </Card>
        </Container>
      )}
    </div>
  );
}

export default TFIDF;
