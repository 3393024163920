import './Home.css';
import { Link } from "react-router-dom";
/* MUI */
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
/* MUI Icons */
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import DataObjectIcon from '@mui/icons-material/DataObject';
import Stack from '@mui/material/Stack';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LanguageIcon from '@mui/icons-material/Language';

function Home() {
  return (
    <div className="App">
      <main>
        <Container maxWidth="lg" sx={{mb: 2}}>
          <Typography
            variant="h1"
            className="Home-slant-header"
            sx={{pt: 3}}
          >
            Four Letter Data
          </Typography>
          <Typography
            variant="h2"
          >
            Tools for web development and digital marketing
          </Typography>
          <Grid container spacing={4} sx={{mt: 2}}>
            <Grid item xs={6}>
              <Card>
                <Stack direction="row" alignItems="flex-start" spacing={0.25}>
                  <CardMedia
                    sx={{
                      m: 4
                    }}
                  >
                    <CloudDownloadIcon fontSize="large" />
                  </CardMedia>
                  <CardContent>
                    <Typography
                      variant="h3"
                    >
                      Site Crawler
                    </Typography>
                    Crawl a site and get URL status, meta data, and on-page content
                  </CardContent>
                </Stack>
                <CardActions sx={{m: 1, justifyContent: "flex-end"}}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/crawl"
                  >
                    Crawl a Site
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <Stack direction="row" alignItems="flex-start" spacing={0.25}>
                  <CardMedia
                    sx={{
                      m: 4
                    }}
                  >
                    <LanguageIcon fontSize="large" />
                  </CardMedia>
                  <CardContent>
                    <Typography
                      variant="h3"
                    >
                      AltLang Check
                    </Typography>
                    Check and verify inline alternate language tags
                  </CardContent>
                </Stack>
                <CardActions sx={{m: 1, justifyContent: "flex-end"}}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/alt-lang"
                  >
                    Check Tags
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <Stack direction="row" alignItems="flex-start" spacing={0.25}>
                  <CardMedia
                    sx={{
                      m: 4
                    }}
                  >
                    <DataObjectIcon fontSize="large" />
                  </CardMedia>
                  <CardContent>
                    <Typography
                      variant="h3"
                    >
                      Schema Search
                    </Typography>
                    Search for pages that contain schema
                  </CardContent>
                </Stack>
                <CardActions sx={{m: 1, justifyContent: "flex-end"}}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/schema-search"
                  >
                    Search for Schema
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <Stack direction="row" alignItems="flex-start" spacing={0.25}>
                  <CardMedia
                    sx={{
                      m: 4
                    }}
                  >
                    <BrowserUpdatedIcon fontSize="large" />
                  </CardMedia>
                  <CardContent>
                    <Typography
                      variant="h3"
                    >
                      URL Fetcher
                    </Typography>
                    Fetch a URL to discover redirect issues, examine meta data, and verify schema and structured data.
                  </CardContent>
                </Stack>
                <CardActions sx={{m: 1, justifyContent: "flex-end"}}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/url-fetch"
                  >
                    Fetch URL
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <Stack direction="row" alignItems="flex-start" spacing={0.25}>
                  <CardMedia
                    sx={{
                      m: 4
                    }}
                  >
                    <ScreenSearchDesktopIcon fontSize="large" />
                  </CardMedia>
                  <CardContent>
                    <Typography
                      variant="h3"
                    >
                      iTunes Search
                    </Typography>
                    Search the Apple App store and iTunes music store for apps, music, TV shows, and movies
                  </CardContent>
                </Stack>
                <CardActions sx={{m: 1, justifyContent: "flex-end"}}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/itunes-search"
                  >
                    Search iTunes
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <Stack direction="row" alignItems="flex-start" spacing={0.25}>
                  <CardMedia
                    sx={{
                      m: 4
                    }}
                  >
                    <TextSnippetIcon fontSize="large" />
                  </CardMedia>
                  <CardContent>
                    <Typography
                      variant="h3"
                    >
                      TF/IDF Analysis
                    </Typography>
                    Analyze unique and common keywords on different pages on a website
                  </CardContent>
                </Stack>
                <CardActions sx={{m: 1, justifyContent: "flex-end"}}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/tfidf"
                  >
                    Crawl and Analyze
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

export default Home;
