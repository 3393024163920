import * as React from 'react';
import { Link } from "react-router-dom";
import './Nav.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

function Nav() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <header className="Nav">
      <AppBar position="static">
        <Toolbar
          disableGutters
          sx={{
            margin: 0,
          }}
        >
          <MenuItem
            component={Link}
            to="/"
          >
            <div className="slantbar">F</div>
            <div className="slantbar lightblue">*</div>
            <div className="slantbar">D</div>
            <div className="slantbar lightblue">A</div>
            <div className="slantbar">T</div>
            <div className="slantbar lightblue">A</div>
          </MenuItem>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ flexGrow: 1,  }}
          >
            {false && (<MenuItem
              component={Link}
              to="/sitemap-editor"
            >
              <Typography
                textAlign="center"
                sx={{
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Sitemap Editor
              </Typography>
            </MenuItem>)}
            <MenuItem
              component={Link}
              to="/crawl"
            >
              <Typography
                textAlign="center"
                sx={{
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Crawl
              </Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to="/schema-search"
            >
              <Typography
                textAlign="center"
                sx={{
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Schema Search
              </Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to="/url-fetch"
            >
              <Typography
                textAlign="center"
                sx={{
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                URL Fetch
              </Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to="/alt-lang"
            >
              <Typography
                textAlign="center"
                sx={{
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                AltLang Check
              </Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to="/itunes-search"
            >
              <Typography
                textAlign="center"
                sx={{
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                iTunes Search
              </Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to="/tfidf"
            >
              <Typography
                textAlign="center"
                sx={{
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                TF/IDF
              </Typography>
            </MenuItem>
          </Stack>
          {false && (<Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{ color: '#FFFFFF' }}
          >
            Account
          </Button>)}
          {false && (<Box>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleClose}>
                <Typography textAlign="center">Profile</Typography>
              </MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
          </Box>)}
        </Toolbar>
      </AppBar>
    </header>
  );
}

export default Nav;
