import React, { useEffect, useState } from 'react';
import {socket} from "../../api/socket";
import './AltLang.css';
import './flags.css';
/* MUI */
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
/* MUI Icons */
import LanguageIcon from '@mui/icons-material/Language';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
/* MUI Colors */
import { green } from '@mui/material/colors';

function AltLang() {
  const [pageData, setPageData] = useState({});
  const [requestURL, setRequestURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState([]);

  const fetchUrl = () => {
    setError("");
    setPageData({});
    setProgress([]);
    !socket.connected && socket.connect();
    socket.on("disconnect", (reason) => {
      //setError(reason);
      setLoading(false);
      setProgress([]);
    });
    socket.emit("altlang_start", requestURL);
  };

  useEffect(() => {
    socket.on("altlang_started", data => {
      setProgress([]);
      setError("");
      setPageData({});
      setLoading(true);
    });
    socket.on("altlang_complete", (data) => {
      setError("");
      setPageData(data);
      setLoading(false);
      setProgress([]);
      socket.disconnect();
    });
    socket.on("altlang_failed", (data) => {
      setError("Couldn't process your request");
      setPageData({});
      setLoading(false);
      setProgress([]);
      socket.disconnect();
    });
    socket.on("altlang_progress", (data) => {
      setProgress(prev => [...prev, data]);
    });
    return () => {
      socket.off("altlang_started");
      socket.off("altlang_complete");
      socket.off("altlang_failed");
      socket.off("altlang_progress");
    }
  }, []);

  const verifyURL = (url) => {
    const crawled = pageData.pages.find((crawled) => {
      return crawled.url === url
    });
    if (crawled === undefined) {
      return (
        <Avatar sx={{
          height: 24,
          width: 24,
            m: 0.25,
        }}><HelpIcon /></Avatar>
      );
    }
    else {
      if (crawled && crawled.status === 200) {
        return (
          <Avatar sx={{
            bgcolor: green[500],
            height: 24,
            width: 24,
            m: 0.25,
          }}><CheckCircleIcon /></Avatar>
        );
      }
      else {
        return (
          <Avatar sx={{
            height: 24,
            width: 24,
            m: 0.25,
          }}><HelpIcon /></Avatar>
        );
      }
    }
  };

  const handleURL = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequestURL(event.target.value);
  };

  return (
    <div className="AltLang">
      <Container maxWidth="100%" sx={(theme) => ({p: 2, backgroundColor: theme.palette.neutral.main})}>
        <Stack spacing={2} direction="row">
          <Typography
            variant="h1"
            sx={{m: "auto", ml: 0, mr: 0}}
          >
            AltLang Check
          </Typography>
          <TextField
            id="url-fetch"
            sx={{ width: 300, backgroundColor: 'white' }}
            label="Check Tags"
            onChange={handleURL}
          />
          <LoadingButton variant="contained" onClick={fetchUrl} loading={loading}>Start</LoadingButton>
        </Stack>
      </Container>
      {error && (
        <Container maxWidth="lg" sx={{mt: 2}}>
          <Alert variant="outlined" severity="error">
            {error}
          </Alert>
        </Container>
      )}
      {Object.keys(progress).length !== 0 && (
        <Container maxWidth="lg" sx={{mt: 2}}>
          <Stack spacing={1} direction="column-reverse" sx={{m: 1}}>
            {progress.map((update, key) => (
              key < Object.keys(progress).length - 1
                ? (<Alert severity="success" key={key}>{update}</Alert>)
                : (
                  <Alert
                    severity="info"
                    icon={
                      <CircularProgress size={20} />
                    }
                    key={key}
                  >
                    {update}
                  </Alert>
                )
            ))}
          </Stack>
        </Container>
      )}
      {pageData && pageData.success && (
        <TableContainer component={Paper} sx={{m: 0, p: 0, maxHeight: 'calc(100vh - 9.5rem)'}}>
          <Table stickyHeader sx={{ minWidth: 200}}  size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                {pageData.hreflangs.map((hreflang, index) => (
                  <TableCell component="th" key={index}>
                    <Stack spacing={0.5} alignItems="flex-start">
                      <Chip
                        avatar={
                          <Avatar>
                            <span className="f16">
                              <div className={`flag ${hreflang.tag.split('-')[1]}`} />
                            </span>
                          </Avatar>
                        }
                        label={hreflang.country !== "" ? hreflang.country : "All"}
                      />
                      <Chip
                        avatar={<Avatar>{hreflang.tag.split('-')[0]}</Avatar>}
                        label={hreflang.language}
                      />
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pageData.analysis.map((alternates, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {alternates.alternatePages.map((page) => (
                    <TableCell scope="row">
                      <Stack direction="row" spacing={0.5}>
                        {verifyURL(page.alturl)}
                        {page.alturl}
                      </Stack>
                      <Stack direction="column">
                        {page.errors.map((error, index) => (
                          <Chip
                            key={index}
                            variant="outlined"
                            avatar={
                              <Avatar>
                                {error['msg'] === 'missing tags' && (<CodeOffIcon />)}
                              </Avatar>
                            }
                            label={error['url']}
                            size="small"
                          />
                        ))}
                      </Stack>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {error === "" && loading === false && Object.keys(pageData).length === 0 && (
        <Container maxWidth="lg" sx={{mt: 2, textAlign: 'left'}}>
          <Card>
            <Stack direction="row" alignItems="flex-start" spacing={0.25}>
              <CardMedia
                sx={{
                  m: 4
                }}
              >
                <LanguageIcon fontSize="large" />
              </CardMedia>
              <CardContent>
                <Typography variant="h3">
                  Alternate Language Check
                </Typography>
                <Typography variant="p">
                  Check and verify inline alternate language tags.
                </Typography>
                <Typography variant="p">
                  Fetched URL data will include
                  <ul>
                    <li>List of country and language targets</li>
                    <li>Pages assigned to each location or language</li>
                    <li>Pages missing tags</li>
                  </ul>
                </Typography>
              </CardContent>
            </Stack>
          </Card>
        </Container>
      )}
    </div>
  );
}

export default AltLang;
