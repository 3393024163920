import './About.css';
/* MUI */
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

function About() {
  return (
    <div className="About">
      <main>
        <Container maxWidth="lg">
          <Typography
            variant="h1"
            className="About-slant-header"
            sx={{pt: 3}}
          >
            About FLD
          </Typography>
          <Typography
            variant="h2"
          >
            Data is a four letter word
          </Typography>
          <p>
            Love Data? Hate Data? F*Data.
          </p>
          <p>
            FourLetterData tools can help verify your website or app is performing correctly so that users and search engines can use your site.
          </p>
          <p>
            FourLetterData.com is developed by Kevin Montgomery.
          </p>
        </Container>
      </main>
    </div>
  );
}

export default About;
