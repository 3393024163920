import React, { useState } from 'react';
import './Itunes.css';
/* MUI */
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Rating from '@mui/material/Rating';
import Skeleton from '@mui/material/Skeleton';
/* MUI Icons */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

function ExpandCell(props: {content: any}) {
  const { content } = props;
  const [open, setOpen] = useState(false);
  
  return (
    <div>
      <Stack direction="row" alignItems="flex-start" spacing={0.25}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <Box>
        {open ?
            <>{content}</>
          : 
            <>{content.substring(0,100)}{content.length > 100 ? '...' : ''}</>
        }
        </Box>
      </Stack>
    </div>
  );
}

function Itunes() {
  const [itunesData, setItunesData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState("software");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchItunes = async () => {
    setLoading(true);
    setItunesData({});
    const response = fetch(`${process.env.REACT_APP_API}/itunes?search=${encodeURIComponent(searchTerm)}&type=${encodeURIComponent(searchType)}`)
      .then(response => {
        if (!response.ok) { throw response }
        return response.json();
      })
      .then((data => {
        setError("");
        setItunesData(data);
        setLoading(false);
      }))
      .catch(error => {
        if (error.status === 429) {
          setError("Too many requests - please wait and try again later");
        }
        else {
          setError("Couldn't process your request");
        }
        setItunesData({});
        setLoading(false);
      });
  }

  const handleSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchType(event.target.value);
  };

  return (
    <div className="Itunes">
      <Container maxWidth="100%" sx={(theme) => ({p: 2, backgroundColor: theme.palette.neutral.main})}>
        <Stack spacing={2} direction="row">
          <Typography
            variant="h1"
            sx={{m: "auto", ml: 0, mr: 0}}
          >
            iTunes Search
          </Typography>
          <FormControl sx={{width: 150, backgroundColor: 'white' }}>
            <InputLabel id="media-type">Media Type</InputLabel>
            <Select
              labelId="media-type-label"
              id="media-type-select"
              value={searchType}
              label="Media Type"
              onChange={handleSearchType}
            >
              <MenuItem value={"software"}>Software</MenuItem>
              <MenuItem value={"music"}>Music</MenuItem>
              <MenuItem value={"movie"}>Movies</MenuItem>
              <MenuItem value={"tvShow"}>TV Shows</MenuItem>
              <MenuItem value={"podcast"}>Podcast</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="itunes-fetch"
            sx={{ width: 300, backgroundColor: 'white' }}
            label="iTunes Search"
            onChange={handleSearchTerm}
          />
          <LoadingButton variant="contained" onClick={fetchItunes} loading={loading}>
            Search iTunes
          </LoadingButton>
        </Stack>
      </Container>
      {error && (
        <Container maxWidth="lg" sx={{mt: 2}}>
          <Alert variant="outlined" severity="error">
            {error}
          </Alert>
        </Container>
      )}
      {itunesData && itunesData.success && (
        <>
          {itunesData.media === "software" ? (
            <TableContainer component={Paper} sx={{m: 0, p: 0, maxHeight: 'calc(100vh - 9.5rem)'}}>
              <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 60 }}></TableCell>
                    <TableCell sx={{ minWidth: 350 }}>App</TableCell>
                    <TableCell align="center">Description</TableCell>
                    <TableCell align="center">Rating</TableCell>
                    <TableCell align="center">Categories</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itunesData.results.length > 0 && itunesData.results.map((row, key) => (
                    <TableRow
                      key={key}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell sx={{maxWidth: 60, verticalAlign: 'top' }}>
                        <Img
                          src={row.artworkUrl60}
                          loading="lazy"
                        />
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        <Stack>
                          <Box>{row.trackName}</Box>
                          <Box><a href={row.sellerUrl} target="_blank" rel="noreferrer">{row.artistName}</a></Box>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        <ExpandCell key={key} content={row.description} />
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        <Stack>
                          <Rating name="read-only" value={row.averageUserRating} readOnly />
                          <Box>{row.userRatingCount} reviews</Box>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{verticalAlign: 'top' }}>
                        <Stack spacing={0.5} direction="row">
                          {row.genres.length > 0 && row.genres.map((genre, key) => (
                            <Chip key={key} label={genre} />
                          ))}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : ""}
          {itunesData.media === "music" ? (
            <TableContainer component={Paper} sx={{m: 0, p: 0, maxHeight: 'calc(100vh - 9.5rem)'}}>
              <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 60 }}></TableCell>
                    <TableCell sx={{ minWidth: 350 }}>Song</TableCell>
                    <TableCell align="center">Artist</TableCell>
                    <TableCell align="center">Collection</TableCell>
                    <TableCell align="center">Genre</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itunesData.results.length > 0 && itunesData.results.map((row, key) => (
                    <TableRow
                      key={key}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell sx={{maxWidth: 60, verticalAlign: 'top' }}>
                        <Img
                          src={row.artworkUrl60}
                          loading="lazy"
                        />
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        <Stack>
                          <Box>{row.trackName}</Box>
                          <Box>{row.trackPrice > 0 ? `$${row.trackPrice}` : 'Free'}</Box>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        {row.artistName}
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        <Stack>
                          <Box>{row.collectionName}</Box>
                          <Box>{row.collectionPrice > 0 ? `$${row.collectionPrice}` : 'Free'}</Box>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{verticalAlign: 'top' }}>
                        <Chip label={row.primaryGenreName} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : ""}
          {itunesData.media === "movie" ? (
            <TableContainer component={Paper} sx={{m: 0, p: 0, maxHeight: 'calc(100vh - 9.5rem)'}}>
              <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 60 }}></TableCell>
                    <TableCell sx={{ minWidth: 350 }}>Movie</TableCell>
                    <TableCell align="center">Rating</TableCell>
                    <TableCell align="center">Description</TableCell>
                    <TableCell align="center">Director</TableCell>
                    <TableCell align="center">Collection</TableCell>
                    <TableCell align="center">Genre</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itunesData.results.length > 0 && itunesData.results.map((row, key) => (
                    <TableRow
                      key={key}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell sx={{maxWidth: 60, verticalAlign: 'top' }}>
                        <Img
                          src={row.artworkUrl60}
                          loading="lazy"
                        />
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        <Stack>
                          <Box>{row.trackName}</Box>
                          <Box>{row.trackPrice > 0 ? `$${row.trackPrice}` : 'Free'}</Box>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        {row.contentAdvisoryRating}
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        <ExpandCell key={key} content={row.longDescription} />
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        {row.artistName}
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        <Stack>
                          <Box>{row.collectionName}</Box>
                          <Box>{row.collectionPrice > 0 ? `$${row.collectionPrice}` : 'Free'}</Box>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{verticalAlign: 'top' }}>
                        <Chip label={row.primaryGenreName} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : ""}
          {itunesData.media === "tvShow" ? (
            <TableContainer component={Paper} sx={{m: 0, p: 0, maxHeight: 'calc(100vh - 9.5rem)'}}>
              <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 60 }}></TableCell>
                    <TableCell sx={{ minWidth: 350 }}>Episode</TableCell>
                    <TableCell align="center">Series</TableCell>
                    <TableCell align="center">Rating</TableCell>
                    <TableCell align="center">Description</TableCell>
                    <TableCell align="center">Collection</TableCell>
                    <TableCell align="center">Genre</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itunesData.results.length > 0 && itunesData.results.map((row, key) => (
                    <TableRow
                      key={key}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell sx={{maxWidth: 60, verticalAlign: 'top' }}>
                        <Img
                          src={row.artworkUrl60}
                          loading="lazy"
                        />
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        <Stack>
                          <Box>{row.trackName}</Box>
                          <Box>{row.trackPrice > 0 ? `$${row.trackPrice}` : 'Free'}</Box>
                          <Box>{row.shortDescription}</Box>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        <Stack>
                          <Box>{row.artistName}</Box>
                          <Box>{row.collectionName}</Box>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        {row.contentAdvisoryRating}
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        <ExpandCell key={key} content={row.longDescription} />
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        <Stack>
                          <Box>{row.collectionName}</Box>
                          <Box>{row.collectionPrice > 0 ? `$${row.collectionPrice}` : 'Free'}</Box>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{verticalAlign: 'top' }}>
                        <Chip label={row.primaryGenreName} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : ""}
          {itunesData.media === "podcast" ? (
            <TableContainer component={Paper} sx={{m: 0, p: 0, maxHeight: 'calc(100vh - 9.5rem)'}}>
              <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 60 }}></TableCell>
                    <TableCell sx={{ minWidth: 350 }}>Episode</TableCell>
                    <TableCell align="center">Podcast</TableCell>
                    <TableCell align="center">Collection</TableCell>
                    <TableCell align="center">Genres</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itunesData.results.length > 0 && itunesData.results.map((row, key) => (
                    <TableRow
                      key={key}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell sx={{maxWidth: 60, verticalAlign: 'top' }}>
                        <Img
                          src={row.artworkUrl60}
                          loading="lazy"
                        />
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        <Stack>
                          <Box>{row.trackName}</Box>
                          <Box>{row.trackPrice > 0 ? `$${row.trackPrice}` : 'Free'}</Box>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        <Stack>
                          <Box>{row.artistName}</Box>
                          <Box>{row.collectionName}</Box>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                        <Stack>
                          <Box>{row.collectionName}</Box>
                          <Box>{row.collectionPrice > 0 ? `$${row.collectionPrice}` : 'Free'}</Box>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{verticalAlign: 'top' }}>
                        <Stack spacing={0.5} direction="row">
                          {row.genres.length > 0 && row.genres.map((genre, key) => (
                            <Chip key={key} label={genre} />
                          ))}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : ""}
        </>
      )}
      {loading === true && (
        <Box sx={{height: "max-content"}}>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          {[...Array(4)].map((item, key) => (
            <Stack key={key} direction="row" spacing={2}>
              <Box sx={{ my: 2, mx: 1, width: '2%'}}>
                <Skeleton variant="circular" width={40} height={40} />
              </Box>
              {[...Array(4)].map((item, key) => (
                <Skeleton key={key} variant="text" sx={{ my: 2, mx: 1, width: '23%'}} />
              ))}
            </Stack>
          ))}
        </Box>
      )}
      {error === "" && loading === false && Object.keys(itunesData).length === 0 && (
        <Container maxWidth="lg" sx={{mt: 2, textAlign: 'left'}}>
          <Card>
            <Stack direction="row" alignItems="flex-start" spacing={0.25}>
              <CardMedia
                sx={{
                  m: 4
                }}
              >
                <ScreenSearchDesktopIcon fontSize="large" />
              </CardMedia>
              <CardContent>
                <Typography variant="h3">
                  iTunes Search
                </Typography>
                <Typography variant="p">
                  Search for pages that contain schema, microdata, structured data, and JSON+LD markup.
                </Typography>
                <Typography variant="p">
                  Enter a search term and select optional schema types to find websites that contain structured data. Most pages with structured data are indexed by Google but not necessarily in the same order.
                </Typography>
                <Typography variant="p">
                  Search results will include
                  <ul>
                    <li>Title, meta description, and thumbnail (if available)</li>
                    <li>Summary of all structured data types found on each page</li>
                    <li>Detailed list of schema properties and data for each schema definition</li>
                  </ul>
                </Typography>
              </CardContent>
            </Stack>
          </Card>
        </Container>
      )}
    </div>
  );
}

export default Itunes;
