import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import './App.css';
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import Home from "../Home/Home";
import Schema from "../Schema/Schema";
import Fetch from "../Fetch/Fetch";
import Terms from "../Terms/Terms";
import About from "../About/About";
import AltLang from "../AltLang/AltLang";
import Itunes from "../Itunes/Itunes";
import TFIDF from "../TFIDF/TFIDF";
import Crawl from "../Crawl/Crawl";

function App() {

  let theme = createTheme({
    palette: {
      primary: {
        main: '#0084ff',
      },
      secondary: {
        main: '#edf2ff',
      },
      neutral: {
        main: '#efefef',
      },
    },
    typography: {
      h1: {
        fontSize: '2rem',
        color: '#0052cc',
      },
      h2: {
        fontSize: '1.75rem',
      },
      h3: {
        fontSize: '1.5rem'
      },
      dataLabel: {
        fontWeight: 900,
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Nav />
      <div className="AppFrame">
        <Routes>
          <Route path="*" element={<Home/>} />
          <Route path="/schema-search" element={<Schema/>} />
          <Route path="/url-fetch" element={<Fetch/>} />
          <Route path="/alt-lang" element={<AltLang/>} />
          <Route path="/itunes-search" element={<Itunes/>} />
          <Route path="/tfidf" element={<TFIDF/>} />
          <Route path="/crawl" element={<Crawl/>} />
          <Route path="/terms" element={<Terms/>} />
          <Route path="/about" element={<About/>} />
        </Routes>
      </div>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
